import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { PostData } from './blog-post.types';
import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import './blog-post.scss';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const BlogPostTemplate = ({
  data,
  location,
}: {
  data: PostData;
  location: any;
}) => {
  const post = data.mdx;
  const { width } = useWindowDimensions();
  const image = getImage(post.frontmatter.image);

  return (
    <Layout
      location={location}
      title="Blog"
      header={{
        shouldRender: width < 768,
        darkMode: true,
        backlink: '/',
      }}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="blog-post-wrapper">
        <article className="blog-post-image-wrapper">
          {image && (
            <GatsbyImage
              className="blog-post-main-image"
              image={image}
              alt="blog post main image"
            />
          )}
          <a
            target="_blank"
            rel="noreferrer"
            className="blog-post-image-attribution"
            href="https://www.freepik.com/vectors/social-media"
          >
            www.freepik.com
          </a>
        </article>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1>{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </header>
          <MDXRenderer>{post.body}</MDXRenderer>
          <footer />
        </article>
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        description
        image {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
  }
`;
